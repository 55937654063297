
import { Options, Vue } from 'vue-class-component';
import { UserClient } from '@/services/Services';
import { getUniversalDate, getUniversalISOStringDate } from '@/utils';
import CommunityCard from '@/components/communityCard.vue';
import * as OM from "@/model";

@Options({
    components: {
        CommunityCard
    }
})
export default class DayEvents extends Vue {

    selectedDay: Date = new Date();
    model: OM.CalendarFIlterVM = new OM.CalendarFIlterVM();
    events: OM.CommunityListVm[] = [];
    shadowInt: number = 1;

    created() {
        this.selectedDay =  getUniversalISOStringDate(getUniversalDate(this.$route.params.day));

        UserClient.getMyEventByDay(<any>this.selectedDay)
        .then(x => {
            this.events = x;
        })
    }

    openEvent(item: OM.CommunityListVm){
        if(!item.isFiera)
            this.$router.push("/events/" + item.identifier);
        else
            this.$router.push("/fiera/communities/" + item.identifier);
    }

}
